import React from 'react';
import {Testimonial, TestimonialContainer, TestimonialHeader, UserImage,} from '../styles';

const Testimonials = () => (
  <TestimonialContainer>
    <TestimonialHeader>Testimonials</TestimonialHeader>
    <UserImage src="//www.slpcertification.com/hosted/images/3b/552ed7d5ed4ba3a81fbe2a1b3003c3/check1x1.jpg"/>
    <h3>Philip Hudson</h3>
    <Testimonial>
        "I started my script today using the SLS model. Already looking at it and I'm so excited to use it I can barely contain myself!
        It looks great so I know I will feel confident using. Certainty, clarity, confidence & courage - I am feeling all four right now."
    </Testimonial>

    <UserImage src="//www.slpcertification.com/hosted/images/1d/476510ba924554aa97b1775ee043ba/white-dude-old1x1.jpg"/>
    <h3>Darren Wilson</h3>
    <Testimonial>
        "I’ve done sales for 14 years and have hit targets from month 2 onwards. After SLS coaching I’ve managed to exceed my target this month by 175%
        and have earned the most commission I ever had."
    </Testimonial>

    <UserImage src="//www.slpcertification.com/hosted/images/5f/3308d57f104be8a2c833d9cbaa43a7/Matt1x1.jpg"/>
    <h3>Alex Smith</h3>
    <Testimonial>
      "SLS coaching with JB and his team changed my life. I am now the #1 salesman in the company. Pretty amazing to see, to say the least! THANK YOU JB."
    </Testimonial>
  </TestimonialContainer>
);

export default Testimonials;
