const { mixpanel } = window;

export const aliasUser = (email) => {
  mixpanel.alias(email);
};

export const userLanded = () => {
/*  const url = new URLSearchParams(window.location.search);
  const source = findSource(url);*/
  mixpanel.track('User on Checkout Page')

};


export const findSource = (urlParams) => {
  const sourceText = urlParams.get('utm_source');
  if (!sourceText) return 'Organic';
  const sourceSplit = sourceText.split('_');

  if (sourceText[0] === 'D') {
    if (sourceText[2] === 'l') {
      return 'LinkedIn';
    }
    return 'Youtube';
  } else if (sourceSplit.length > 1) {
    return sourceSplit[0];
  } else {
    return urlParams.get('utm_source');
  }
};