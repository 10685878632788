import React from 'react';
import {ChildDiv, Img, ParentDiv, Text, TextContainer,} from '../styles';
import './SelectProduct.css';
import checkoutPage from '../images/checkoutPage.png';

const SelectProduct = () => {
    return (
        <ParentDiv>
            <ChildDiv>
                <h2 className="selectHeader"> CLAIM YOUR EXCLUSIVE SUCCESS RESOURCES DISCOUNT! </h2>
                <Img className="selectPhoto" src={checkoutPage} style={{width: '50vw'}}
                     alt="Straight Line Sales Image"/>
                <TextContainer>

                    <Text>
                        The Certification Program Package includes <b>twelve comprehensive courses</b>, each containing
                        between six
                        and ten content-packed lessons.
                    </Text>

                    <Text>
                        You will also receive <b>Jordan’s famous Script Builder and Rebuttals Guide</b>. These two
                        powerful tools
                        will give you the precise language you need to write highly effective scripts, as well as
                        provide
                        you with rebuttals for overcoming every conceivable objection.
                    </Text>

                    <Text>
                        In addition, you will receive <b>Daily Motivations and Weekly Coaching Sessions</b> that are
                        custom
                        tailored to correspond to the specific topics you are currently studying as you progress through
                        your training.
                    </Text>
                    <Text>
                        <b> Monthly live Q&A sessions</b> will further deepen your comprehension and retention for the
                        duration of
                        the twelve week course
                    </Text>

                </TextContainer>
            </ChildDiv>
        </ParentDiv>
    );
};

export default SelectProduct;
