import React, {useEffect} from 'react';
import Header from './Header';
import SelectProduct from './SelectProduct';
import ContactForm from './ContactForm';
import Testimonials from './Testimonials';
import './App.css';
import {userLanded} from '../api/mixpanel';


const AppContainer = () => {
  useEffect(() => {
    userLanded();
  }, []);

  return (
    <div className="app">
      <Header />
      <div className="childOne">
        <div className="childTwo">
          <SelectProduct />
          <div>
            <ContactForm />
          </div>
        </div>
        <div>
          <Testimonials />

        </div>
      </div>
    </div>
  );
};

export default AppContainer;
