import React from 'react';
import PhoneInput from 'react-phone-number-input';
import {checkEmailIsValid, checkPhoneNumber, validateName} from '../api/validationSchema';
import {createPaymentIntent, createTransaction, createUser} from '../api/transactionsApi';
import './ContactForm.css';
import 'react-phone-number-input/style.css';
import CreditCardForm from './CreditCardForm';
import LoadingModal from './LoadingModal';
import ErrorModal from './ErrorModal';

class ContactForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      paymentOption: null,
      firstName: null,
      lastName: null,
      email: null,
      country: null,
      disableSubmit: true,
      phoneNumber: null,
      clientSecret: null,
      loading: false,
      error: false,
      errorMessage: null
    };

    this.onSubmit = this.onSubmit.bind(this);
  }

  componentDidMount() {
    const { country } = this.state;
    if (country === null) {
      this.getCountry();
    }
  }

  async onSubmit(e) {
    e.preventDefault();
    // if (process.env.NODE_ENV === 'development') {
    //   const firstName = 'd';
    //   const lastName = 'g';
    //   const email = 'test30@test.com';
    //   const phoneNumber = '13238074248';
    // }
    this.setState({ loading: true });
    const { firstName, lastName, email, phoneNumber, paymentOption } = this.state;
    const productId = parseInt(paymentOption);
    let buyPrice = null;
    if (productId === 23) {
      buyPrice = 1997;
    }
    const userCreationDto = { firstName, lastName, email, phone: phoneNumber };
    const userId = await createUser(userCreationDto)
      .catch(() => this.setState({error: true, errorMessage: 'User already exists! Please contact support.'}));
    const transactionId = await createTransaction({ userId, productId, paymentProvider: 'STRIPE', buyPrice })
      .catch(() => this.setState({error: true, errorMessage: 'Your order has failed, please contact support. Error:103'}));
    this.setState({ transactionId });
  console.log(transactionId, '<-- t id');
    const clientSecret = await createPaymentIntent(transactionId)
      .catch((e) => {
        console.error(e);
        this.setState({
          error: true,
          errorMessage: 'Your order has failed, please contact support. Error:104',
        });
      });

    this.setState({ clientSecret });
    this.setState({ loading: false });
  }

  async getCountry() {
    const call = await fetch('https://ipapi.co/json/');
    const res = await call.json();
    if (res.country) {
      this.setState({ country: res.country });
    }
  }

  clearError = () => this.setState({ error: false });

  handleOptionChange = ({ target: { name, value } }) => {
    this.setState({
      [name]: value,
    });
  };

  handlePhoneNumber = (value) => {
    checkPhoneNumber(value).then((valid) => {
      console.log(valid);
      if (valid) {
        this.setState({ phoneNumber: value });
      }
    });
  };

  handleEmail = ({ target: { value } }) => {
    if (checkEmailIsValid(value)) {
      this.setState({ email: value });
    }
  };

  handleNames = ({ target: { value, name } }) => {
    if (validateName(value)) {
      this.setState({ [name]: value });
    }
  };

  isNotVerified = ({ firstName, lastName, email, phoneNumber, paymentOption }) => {
    if (process.env.NODE_ENV === 'production') {
      if (firstName && lastName && email && phoneNumber && paymentOption !== null) {
        return false;
      }
      return true;
    }
    return false;
  };

  render() {
    const {paymentOption,  phoneNumber, country, clientSecret, transactionId, loading, error, errorMessage } = this.state;
    if (!clientSecret && !loading && !error) {
      return (
        <div>
          <form className="total-form" onSubmit={this.onSubmit}>
            <div className="payment-container">
              <h3 className="contact-header">Payment Options</h3>
         {/*     <input
                type="radio"
                id="threeMonth"
                value="24" // productId 24
                className="radio-btn"
                onChange={this.handleOptionChange}
                name="paymentOption"
                checked={paymentOption === '24'}
              />
              <label htmlFor="threeMonth">3 months, $998.00 per month</label>*/}

              <input
                  type="checkbox"
                  id="oneTime"
                  value="23" // productId 23 buyPrice 2495
                  className="radio-btn"
                  onChange={this.handleOptionChange}
                  name="paymentOption"
                  checked={paymentOption === '23'}
              />
              <label htmlFor="oneTime" className="oneTime">One Time Payment of $1,997.00 <span>(RETAIL: <span className="strikeout">$8,317.00</span>)</span></label>
            </div>

            <div className="contact-container">
              <h3 className="contact-header">Your Contact Information</h3>
              <input name="firstName" type="text" placeholder="Enter first name here" className="input" onChange={this.handleNames} />
              <br/>
              <input name="lastName" type="text" placeholder="Enter last name here" className="input" onChange={this.handleNames} />
              <br/>
              <input name="email" type="text" placeholder="Enter email here" className="input" onChange={this.handleEmail} />
              <br/>
              <PhoneInput
                country={country}
                placeholder="Enter phone number"
                value={phoneNumber}
                onChange={(value) => this.handlePhoneNumber(value)}
              />
              <br/>
            </div>
            <button disabled={this.isNotVerified(this.state)} type="submit" className="process-button">Process your order</button>
          </form>
        </div>
      );
    }
    return (
      <>
        { error ? <ErrorModal message={errorMessage} clearError={this.clearError} /> : null}
        { loading ? <LoadingModal message="Submitting Contact Information." /> : null }
        { !loading && clientSecret ? <CreditCardForm paymentIntent={clientSecret} transactionId={transactionId} /> : null }
      </>
    );
  }
}

export default ContactForm;
