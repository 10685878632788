import axios from 'axios';

const headers = {
  'Content-type': 'application/json',
};

export const createUser = async (userCreationDto) => {
  const { data: { id } } = await axios.post('https://thewolfhub.com/users/allow-exists', userCreationDto);
  return id;
};

export const createTransaction = async (transactionCreationDto) => {
  const { data } = await axios.post('https://thewolfhub.com/transactions/cart', [transactionCreationDto], { headers }).catch((e) => console.log(e));
  const transactionId = data[0].id;
  return transactionId;
};

export const createPaymentIntent = async (transactionId) => {
  const { data: { clientSecret } } = await axios.post(`https://thewolfhub.com/transactions/intent/${transactionId}`, null, { headers });
  return clientSecret;
};

export const runPaymentIntent = async (transactionId, intentId) => {
  const { data } = await axios.post(`https://thewolfhub.com/transactions/run/${transactionId}/intent/${intentId}`, null, { headers });
  console.log(data);
  return data;
};
