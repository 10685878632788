import React from 'react';
import Logo from '../images/Logo.png';
import {LogoImg} from '../styles/SelectProductStyles';
import {HeaderContainer} from '../styles/HeaderStyles';
import './Header.css';

const Header = () => {
    return (
        <HeaderContainer>
            <LogoImg src={Logo} alt="SLS Logo" />
            <h1 className="headerText">
                How To Master Persuasion, Close More Deals, & Become a World Class Closer
            </h1>
        </HeaderContainer>
    );
};

export default Header;
