import React from 'react';
import {ClipLoader} from "react-spinners";
import './LoadingModal.css';

const LoadingModal = (props) => {
  return (
    <div className="backdrop">
      <div className="form">
        <ClipLoader sizeUnit="px" size={100} color="black"/>
        <h3 className="loading-message">{props.message}</h3>
      </div>
    </div>
  )
};

export default LoadingModal;
