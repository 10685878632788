import React, {Component} from 'react';
import {Elements} from 'react-stripe-elements';
import NestedStripe from './NestedStripe';

class Stripe extends Component {
  render() {
    const { paymentIntent: intent, transactionId } = this.props;
    return (
      <Elements>
        <NestedStripe
          intent={intent}
          transactionId={transactionId}
        />
      </Elements>
    );
  }
}

export default Stripe;
