import React from 'react';
import './LoadingModal.css';

const ErrorModal = (props) => {
  return (
    <div className="backdrop">
      <div className="form">
        <h3 className="loading-message">{props.message}</h3>
        <button type="button" className="button" onClick={props.clearError}>Okay</button>
      </div>
    </div>
  )
};

export default ErrorModal;
