import React from 'react';
import Stripe from './Stripe';

const CreditCardForm = (props) => {
  // eslint-disable-next-line react/prop-types
  const { paymentIntent, transactionId } = props;
  return (
    <div>
      <Stripe paymentIntent={paymentIntent} transactionId={transactionId} />
    </div>
  );
};

export default CreditCardForm;
