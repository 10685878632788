import axios from 'axios';

export const checkPhoneNumber = async (phoneNumber) => {
  if (!phoneNumber) return false;
  console.log(phoneNumber.length, phoneNumber);
  const sanitizedNumber = phoneNumber.replace(/\D/g, '');
  console.log(sanitizedNumber);
  const { data: { valid } } = await axios.get(`https://apilayer.net/api/validate?access_key=c70aa5b623a2407466f1075d9fe54a52&number=${sanitizedNumber}&country_code=&format=1`);
  return valid;
};

export const checkEmailIsValid = (email) => {
  if (!email) return false;
  return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
};

export const validateName = (name) => name.length > 0;
