/* eslint-disable */
import React, {Component} from 'react';
import {CardElement, injectStripe} from 'react-stripe-elements';
import './NestedStripe.css';
import {runPaymentIntent} from '../api/transactionsApi';
import {navigate} from '@reach/router';
import LoadingModal from './LoadingModal';

class NestedStripe extends Component {
  constructor(props) {
    super(props);
    this.state = {
      transaction: null,
      loading: false,
      error: false
    };
    this.submit = this.submit.bind(this);
  }

  submit() {
    const { transactionId, intent, stripe } = this.props;
    this.setState({loading: true});

    stripe.handleCardPayment(intent)
      .then((payload) => {
        if (payload.error) {
          this.setState({
            transaction: null,
            loading: false,
            error: true
          });
          console.log('[error]', payload.error);
        } else {
          this.setState({
            transaction: "success"
          });
          runPaymentIntent(transactionId, payload.paymentIntent.id)
            .then(() => {
              console.log('Success!!!!!');
              navigate("/thankYou");
            })
        }
      });
  }

  render() {
    return (
      <div className="nested-stripe-container">
        { this.state.loading ? <LoadingModal message="Processing payment." /> : null }
        <h2 className="payment-header">Payment Information</h2>
        <CardElement style={{base: {fontSize: '18px'}}}/>
        <button className="orderBtn" onClick={this.submit}>Place Order</button>
      </div>
    );
  }
}

export default injectStripe(NestedStripe);
