import styled from 'styled-components';

export const ParentDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  min-height: calc(100vh - 20px);
  margin-top: 25px;
  
   @media (min-width: 722px) {
    padding-left: 15px;
  }
  
  @media (min-width: 1024px) {
    min-height: 0;
  }
`;

export const ChildDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Img = styled.img`
  width: 300px;
  @media (max-width: 414px) {
    width: 225px;
  }
`;

export const LogoImg = styled.img`
  width: 15em;
  margin-left: auto;
  margin-right: auto;
`;

export const TextContainer = styled.div`
  max-width: 900px;
  margin-top: 25px;
  
  @media (max-width: 585px) {
    padding: 0 15px 0 15px;
  }
`;

export const Text = styled.p`
  font-size: 1.15rem;
  font-family: sans-serif;
  letter-spacing: .5px;
  line-height: 25px;
  
  @media (max-width: 414px) {
    font-size: .85rem;
    letter-spacing: .5px;
    line-height: 20px;
  }
  
  @media (max-width: 768px) {
    padding-left: 15px;
  }
`;

// export const PaymentPlan = styled.div`
//   display: flex;
//   flex-direction: column;
//   text-align: center;
//   margin-left: 35px;
// `;
//
// export const PaymentPlanHeader = styled.h2`
//   font-family: sans-serif;
//   letter-spacing: .5px;
// `;
//
// export const ButtonContainer = styled.div`
//   display: flex;
//   flex-direction: row;
// `;
//
// export const Button = styled.button`
//   font-size: 1rem;
//   letter-spacing: .5px;
//   font-family: sans-serif;
//   border-radius: 4px;
//   cursor: pointer;
//   padding: 8px;
//   margin: 7px;
//   background-color: #ffc627;
//   color: black;
//   border: none;
//   :hover {
//     background-color: black;
//     color: #ffc627;
//    }
// `;

export const Testimonial = styled.div`
  width: 350px;
  font-family: sans-serif;
  letter-spacing: .5px;
  margin-bottom: 45px;
  line-height: 25px;
  
  @media (max-width: 320px) {
    width: 250px;
    font-size: .85rem;
  }
  
  @media (min-width: 360px) and (max-width: 375px) {
    width: 310px;
    font-size: .85rem;
  }
  
  @media (min-width: 376px) and (max-width: 414px) {
    width: 340px;
    font-size: .85rem;
  }
  
   @media (min-width: 720px) {
      width: 260px;
      font-size: 1rem;
   } 
`;

export const TestimonialContainer = styled.div`
  @media (max-width: 720px) {
    margin-left: auto;
    margin-right: auto;
  }
  
  @media (max-width: 768px) {
    margin-left: 35px;
    padding-right: 15px;
  }
  
  @media (min-width: 769px) {
    margin-left: 75px;  
    padding-right: 15px;
  }
`;

export const TestimonialHeader = styled.h1`
  font-family: sans-serif;
  letter-spacing: .5px;
  text-align: center;
`;

export const UserImage = styled.img`
  width: 100px;  
`;

// export const UserContainer = styled.div`
//   margin-bottom: 25px;
// `;
