import React from 'react';
import {FaThumbsUp} from 'react-icons/fa';
import './ThankYou.css';
import ClickHereBanner from '../images/thankyouBannerMobile.jpg';
import DesktopBanner from '../images/notextDesktopBanner.png'


const ThankYou = () => {
    /*  setTimeout(() => {
        navigate("https://jordanbelfort.com/");
      }, 5000);*/

    return (
        <div className="thank-you-container">
            <div>
                <img alt={"Banner"} src={DesktopBanner}/>
            </div>
            <div className="thankyouSubRoot">


                <FaThumbsUp size="4em"/><br/><br/>
                <h1 className="thank-you-header">Thank you for your purchase!</h1>
                <h2 className="thank-you-subHeader">Check out <a className="shopJbLink" target="_blank" rel="noopener noreferrer" href={"https://shop.jordanbelfort.com/?utm_source=webinar"}>shop.jordanbelfort.com</a> for an additional 70% OFF everything in our store.</h2>
                <div>
                    <a  target="_blank" href={"https://shop.jordanbelfort.com/?utm_source=webinar"} rel="noopener noreferrer" >
                        <img src={ClickHereBanner} height={440} alt={"Banner"}/>
                    </a>
                </div>


            </div>
        </div>
    );
};

export default ThankYou;
