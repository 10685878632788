/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import ReactDOM from 'react-dom';
import {StripeProvider} from 'react-stripe-elements';
import App from './components/App';

const stripeKey = process.env.NODE_ENV !== 'production' ? 'pk_test_NWV4IxfTTJUfb8ed1aPvUZNm' : 'pk_live_YZjIz5GCjTtmjQUxVsCC13Dt';

const app = (
  <StripeProvider apiKey={stripeKey}>
    <App />
  </StripeProvider>
);

ReactDOM.render(app, document.getElementById('root'));
