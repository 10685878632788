import React, {Component} from 'react';
import {Router} from '@reach/router';
import ThankYou from './ThankYou';
import AppContainer from './AppContainer';
import './App.css';

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <Router>
        <AppContainer path="/" />
        <ThankYou path="/thankYou" />
      </Router>
    );
  }
}

export default App;
